body {
    width: 100vw;
    height: 100vh;
    margin : 0;
    overflow: hidden;
}

.container::-webkit-scrollbar {
    display: none;
  }

.container {
    overflow: hidden;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

section {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    margin: 0;
    text-align: center;

    
}


h1{
    font-family: Arial;
    font-size: 15mm;
}

.viewOne{
    background-color: rgb(28,28,28);
    color: green;
}

.viewTwo{
    background-color: rgb(38,38,38);
    color: blue;
}

.viewThree{
    background-color: rgb(48,48,48);
    color: yellow;
}