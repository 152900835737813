.button {
    position: absolute;
    top: 200px;
    left: 50px;
    display: flex;
    height: 35px;
    padding: 0;
    background: #0094a1;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Abel', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
  
  .button:hover {
    background: #00808b;
  }
  
  .button:active {
    background: #0094a1;
  }
  
  .button__text,
  .button__icon {
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    color: #fff;
    height: 100%;
  }
  
  .button__icon {
    font-size: 1.5em;
    background: rgba(0, 0, 0, 0.08);
  }



  .button2 {
    position: absolute;
    top: 200px;
    left: 250px;
    display: flex;
    height: 35px;
    padding: 0;
    background: #950068;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Abel', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
  
  .button2:hover {
    background: #81005a;
  }
  
  .button2:active {
    background: #950068;
  }
  
  .button3 {
    position: absolute;
    top: 200px;
    left: 440px;
    display: flex;
    height: 35px;
    padding: 0;
    background: orange;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Abel', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
  
  .button3:hover {
    background: rgb(219, 143, 0);
  }
  
  .button3:active {
    background: orange;
  }

  .button4 {
    position: absolute;
    top: 72vh;
    left: 46%;
    display: flex;
    height: 30px;
    padding: 0;
    background: #950068;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Abel', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    opacity: 100;
    filter: alpha(opacity=80);
  }
  
  .button4:hover {
    background: #81005a;
  }
  
  .button4:active {
    background: #950068;
  }

  .button5{
    display: flex;
    height: 35px;
    bottom: 1px;
    padding: 0;
    background: #dbba00;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Abel', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    opacity: 100;
    filter: alpha(opacity=80);

  }

  .button5:hover {
    background: #cfb000;
  }
  
  .button5:active {
    background: #dbba00;
  }