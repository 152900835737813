img{
    display: block;
    width: 100vw;
    height: 100vh;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
    margin: auto;
    overflow-x:visible;
    
}

main{
    position:absolute;
}



.imgBack{
    position:relative;
    z-index:1;
}

.Cloud1{
    position:absolute;
    z-index: 1;
    width:  570px;
    height: 290px;
    left: 1100px;
    top:10px;
    transition: transform 70s ease-in;
    animation: right-to-left 70s ease-in infinite;

}

.Cloud2{
    position:absolute;
    z-index: 1;
    width:  640px;
    height: 130px;
    left: 1100px;
    top:80px;
    transition: transform 100s ease-in;
    animation: fast-delayed-right-to-left 100s ease-in infinite;

}

.Cloud3{
    position:absolute;
    z-index: 1;
    width:  580px;
    height: 180px;
    left: 1100px;
    top:230px;
    transition: transform 90s ease-in;
    animation: delayed-right-to-left 90s ease-in infinite;

}

.Cloud6{
    position:absolute;
    z-index: 1;
    width:  40px;
    height: 30px;
    left: 1100px;
    top:300px;
    transition: transform 100s ease-in;
    animation: small-delayed-right-to-left 100s ease-in infinite;

}




@keyframes right-to-left{
    70%{
        transform: translateX(-420%)
    }
    100%{
        transform: translateX(-420%)
    }
}

@keyframes delayed-right-to-left{
    30%{
        transform: translateX(0)
    }
    80%{
        transform: translateX(-420%)
    }
    100%{
        transform: translateX(-420%)
    }
}

@keyframes fast-delayed-right-to-left{
    40%{
        transform: translateX(0)
    }
    60%{
        transform: translateX(-450%);
    }
    100%{
        transform: translateX(-450%)
    }
}

@keyframes small-delayed-right-to-left{
    40%{
        transform: translateX(0)
    }
    50%{
        transform: translateX(-5000%);
        opacity:0;
    }
    100%{
        transform: translateX(-5000%)
    }
}


@media (max-width: 500px) {
    .nameText p{
        transform:translate(-200%);
        font-size:10px;
    }
    .nameText h1{
        transform:translate(-10%);
        text-align:center;
    }
    .nav{
        visibility:hidden;
    }
    .nameText h2{
        transform:translate(3%,150%);
        text-align:left;
        margin-left:-40%;
    }
    .imgBuilding {
        right:-377px;
        width:1290px;
    }
    .button{
        transform: translate(50%,100%);
    }
    .button2{
        transform: translate(-64%,220%);
    }
    .button3{
        transform: translate(-174%,340%);
    }
    .imgBack{
        z-index:0;
    }
    
  }

.imgGround{
    position:relative;
    z-index:3;
}

.imgBuilding{
    position:absolute;
    z-index:2;
}

.imgSky{
    position:absolute;
    z-index:0;
}

.levelContainer{
    position: relative;
    z-index: 4;
}

.nameText{
    -webkit-text-stroke-width: 1px;
    font-family: 'Fjalla One', sans-serif;
    text-shadow: 4px 4px 12px black;
    display: flex;
    justify-content: center;
    flex-direction:column;
    justify-items:center;
}

.nameText h1{
    top: 21px;
    left: 100px;
    color: rgb(246, 246, 0);   
    text-shadow: 5px 5px 1px 4px black;
    position: absolute;
}

.nameText h2{
    top: 95px;
    left: 148px;
    color: greenyellow;
    text-shadow: 2px 2px 3px 4px black;
    font-size: 35px;
    font-style:normal;
    font-family: 'Abel', sans-serif;
    position: absolute;
}

.nameText p{
    top: 28px;
    left: 305px;
    color: white;   
    font-size: 20px;
    font-family: 'Abel', sans-serif;
    position: absolute;
}

.fade-in{
    transition: opacity 1s ease;
}

.fade-out{
    opacity: 0;
    transition: opacity 1s ease;
}

.nav{
    position:relative;
    z-index:5;
    color:white;
    font-family:Abel;
    font-size:30px;
    text-shadow: 4px 4px 0px grey;

  }

  .nav h1{
    position:absolute;
    z-index:5;
    color:white;
    font-family:Abel;
    font-size:25px;
    text-shadow: 4px 4px 0px rgb(12, 149, 0);
    left:50%;
    transform:translate(-50%);
    margin-top:47%;
    animation: fadeoutin 17s ease-in infinite;
    opacity:0%;
  }

  @keyframes fadeoutin{
    20%{
        opacity:0%;
    }
    50%{
        opacity:100%;
        color:white;
    }
    51%{
        color:yellow;
    }
    54%{
        color:white;
    }
    100%{
        opacity:0%;
    }
}

@media (min-aspect-ratio:21/9){
    .Cloud1{
        left:1800px;
    }
    .Cloud2{
        left:1800px;
    }
    .Cloud3{
        left:1800px;
    }
    .Cloud6{
        left:1800px;
    }
    .nav h1{
        margin-top:36%;
    }
}